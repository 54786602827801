<template id="payment">
    <section>
        <form
            class="padded-container payment-layout__container"
            ref="paymentForm"
            v-on:submit.stop.prevent>
            <div v-if="!isEditPayment" class="payment-layout__step-text">
                <h1 class="payment-layout__step-title">
                    {{ getStepTitle }}
                </h1>
                <div class="payment-layout__step-subtitle">
                    {{ getSubHeading }}
                </div>
            </div>
            <div
                class="aa-layout"
                v-bind:class="[
                    isEditPayment ? 'payment-layout--edit account-grid' : 'payment-layout',
                    { 'payment-summary__layout': enhancedPaymentSummaryEnabled },
                    { '-no-steps-header': !showStepsHeader },
                ]"
                :aa-region="formConfig.aaRegion">
                <steps-header
                    v-if="showStepsHeader"
                    :class="[
                        'payment-layout__steps-header qt-payment-header',
                        { '-steps-header-full-width': enhancedPaymentSummaryEnabled },
                    ]" />

                <div
                    v-if="isEditPayment"
                    class="grid-item grid-item--payment-row grid-item--row-bordered sub-page--header aa-layout">
                    <h3>
                        {{ pageAttribute.get('ap_page_title', $getLocale('account_payment')) }}
                    </h3>
                </div>

                <!-- tabs menu -->
                <div
                    :class="['payment-layout__tabs', { '-no-steps-header': !showStepsHeader }]"
                    role="tablist"
                    aria-orientation="horizontal">
                    <template v-if="featurePaypal && featureFlagPaypal">
                        <a
                            class="tab qt-cctab"
                            role="tab"
                            id="tab-cards"
                            :aria-selected="activeTab === tab.cc ? 'true' : 'false'"
                            aria-controls="panel-cards"
                            tabindex="1"
                            @keydown.enter.prevent="handleEnterKey"
                            @click="handleClickTab($event, tab.cc)"
                            aria-label="Credit Card">
                            <CreditCardLogos :cards="this.acceptedCards" />
                        </a>
                        <a
                            class="tab tab--payPal qt-paypaltab"
                            role="tab"
                            id="tab-paypal"
                            :aria-selected="activeTab === tab.payPal ? 'true' : 'false'"
                            aria-controls="panel-paypal"
                            tabindex="1"
                            @keydown.enter.prevent="handleEnterKey"
                            @click="handleClickTab($event, tab.payPal)"
                            aria-label="PayPal"></a>
                        <div :class="tabIndicatorClass"></div>
                    </template>
                    <template v-else>
                        <div
                            class="tab qt-cctab"
                            role="tab"
                            id="tab-cards"
                            :aria-selected="'true'"
                            aria-controls="panel-cards"
                            tabindex="1"
                            aria-label="Credit Card">
                            <CreditCardLogos :cards="this.acceptedCards" />
                        </div>
                        <div :class="tabIndicatorClass"></div>
                    </template>
                </div>

                <!-- START OF AB TEST PLOCTOPLUS-1915: center alignment payment page -->
                <!-- payment form tab (credit card/paypal): no AB Test, center alignment payment page AB TEST, control, variants A and B -->
                <transition-group
                    name="fade"
                    tag="div"
                    :class="[
                        { ...getPaymentFormTabsModuleClass },
                        { '-no-steps-header': !showStepsHeader },
                    ]">
                    <div
                        v-show="activeTab === tab.cc"
                        :key="tab.cc"
                        id="panel-cards"
                        role="tabpanel"
                        aria-labelledby="tab-cards"
                        :class="{ 'payment-layout__tab-content__cc': true }">
                        <div v-if="formErrorMessage" role="alert" class="form-message error">
                            <p v-if="formErrorMessage">
                                {{ formErrorMessage }}
                            </p>
                        </div>

                        <!-- START AB TEST PLOCTOPLUS-2033 : Added @recurlyFocus -->
                        <FormFields
                            v-show="activeTab === tab.cc"
                            :key="tab.cc"
                            class="form-cc-payment"
                            v-bind:class="[
                                registrationCountry ? countryFormClass : '',
                                { long: altPaymentFormView },
                            ]"
                            :formFieldList="formFieldList"
                            :fieldErrors="fieldErrors"
                            @updateFormData="updateFormData"
                            @validateField="validateField"
                            @validateForm="validateForm"
                            @recurlyFocus="handleRecurlyFocus"></FormFields>
                        <!-- END AB TEST PLOCTOPLUS-2033 : Added @recurlyFocus -->
                    </div>

                    <div
                        v-show="activeTab === tab.payPal"
                        :key="tab.payPal"
                        id="panel-paypal"
                        role="tabpanel"
                        aria-labelledby="tab-paypal"
                        class="payment-layout__tab-content__paypal">
                        <transition-group
                            name="fade"
                            tag="div"
                            class="paypal-message qt-paypalcopytxt">
                            <p
                                v-if="payPalToken"
                                key="paypal-success"
                                class="paypal-message__heading paypal-message__success-heading">
                                {{ paypalSuccessMessage }}
                            </p>

                            <div v-else key="paypal-instruction">
                                <p class="paypal-message__heading">
                                    {{
                                        $getLocale(
                                            'you_will_be_directed_to_the_paypal_website_to_complete_your_payment',
                                        )
                                    }}
                                </p>

                                <p class="paypal-message__body">
                                    {{
                                        $getLocale(
                                            'this_will_be_saved_as_the_default_payment_method_for_your_subscription._You_can_change_your_payment_method_at_any_time_by_visiting_your_account_settings',
                                        )
                                    }}
                                </p>
                            </div>
                        </transition-group>
                    </div>
                </transition-group>

                <!-- order summary and agreement copy: PLOCTOPLUS-1915 center alignment payment page AB TEST, variant A and no AB Test -->
                <div
                    v-if="!isEditPayment && !isCenterAlignPaymentPageModulesAbove"
                    :class="[
                        'order-summary-wrapper',
                        'payment-layout__summary',
                        { '-no-steps-header': !this.showStepsHeader },
                    ]">
                    <OrderSummary
                        @found-price="updatePrice"
                        :coupon="coupon"
                        :couponCheckPending="couponCheckPending"
                        :couponDisclaimer="couponDisclaimerCopy"
                        @applyCoupon="applyCoupon"
                        @updateOrder="updateOrder"
                        @resetCoupon="resetCoupon"
                        @trialEndDateFormatted="updateTrialEndDate"
                        ref="orderSummary"
                        :recurly="recurly"
                        :annualPlanOption="annualPlanOption"
                        :formConfig="orderSummaryConfig"
                        v-if="!enhancedPaymentSummaryEnabled"></OrderSummary>
                    <BillingAgreementSummary
                        @found-price="updatePrice"
                        :coupon="coupon"
                        :couponCheckPending="couponCheckPending"
                        :couponDisclaimer="couponDisclaimerCopy"
                        @applyCoupon="applyCoupon"
                        @updateOrder="updateOrder"
                        @resetCoupon="resetCoupon"
                        @trialEndDateFormatted="updateTrialEndDate"
                        ref="orderSummary"
                        :recurly="recurly"
                        :annualPlanOption="annualPlanOption"
                        :formConfig="orderSummaryConfig"
                        :promo="this.appliedCouponCode"
                        :pricing="pricing"
                        :pageAttr="pageAttr"
                        :couponDiscountBillingDate="getCouponDiscountBillingDate"
                        :nextNonZeroBillingPrice="nextNonZeroBillingPrice"
                        v-if="enhancedPaymentSummaryEnabled"></BillingAgreementSummary>
                    <PaymentAnnualUpsell
                        v-if="showPaymentAnnualUpsell"
                        :title="pageAttr.aa_payment_annual_upsell_heading_title"
                        :disclaimer="pageAttr.aa_payment_annual_upsell_disclaimer"
                        :paymentMethod="activeTab"
                        :planTitle="this.plan.titleForTracking"
                        :coupon="coupon"></PaymentAnnualUpsell>
                    <p
                        v-if="!isEditPayment && displayStartCBSButton"
                        v-html="getAgreementCopy()"
                        class="payment-layout__summary__agreement"></p>

                    <input type="hidden" name="recurly-token" data-recurly="token" />

                    <template v-if="needsWaiveOfCoolingRights && displayStartCBSButton">
                        <CheckboxElm
                            data-testid="waiveOfCoolingRighsCheckbox"
                            :checkboxConfig="waiveOfCoolingRighsCheckboxConfigs"
                            :errorMessage="waiveOfCoolingRighsCheckboxErrorMessage"
                            @change="setWaiveOfCoolingRights($event)"></CheckboxElm>
                        <div
                            data-testid="waiveOfCoolingRighsCheckboxError"
                            role="alert"
                            aria-live="polite"
                            class="text-input-error"
                            v-if="waiveOfCoolingRighsCheckboxErrorMessage">
                            {{ waiveOfCoolingRighsCheckboxErrorMessage }}
                        </div>
                    </template>

                    <FormButton
                        v-if="displayStartCBSButton"
                        :buttonConfig="buttonConfig"
                        :buttonState="submitButtonState"
                        @click="onClickSubmit"></FormButton>
                    <button
                        v-else
                        class="payment-layout__summary__submit paypal-checkout qt-paypalbtn"
                        @click="handlePayPalCheckout($event, paypalGateway)">
                        <span class="label">{{ $getLocale('checkout_with') }}</span>
                        <span class="logo" aria-label="PayPal"></span>
                    </button>

                    <div class="payment-layout__status" role="status" aria-live="polite">
                        {{ submitStatus }}
                    </div>
                </div>

                <!-- order summary: PLOCTOPLUS-1915 center alignment payment page AB TEST, variant B -->
                <div
                    v-if="!isEditPayment && isCenterAlignPaymentPageModulesAbove"
                    class="order-summary-wrapper payment-layout__summary order-summary-module">
                    <OrderSummary
                        @found-price="updatePrice"
                        :coupon="coupon"
                        :couponCheckPending="couponCheckPending"
                        :couponDisclaimer="couponDisclaimerCopy"
                        @applyCoupon="applyCoupon"
                        @updateOrder="updateOrder"
                        @resetCoupon="resetCoupon"
                        ref="orderSummary"
                        :recurly="recurly"
                        :annualPlanOption="annualPlanOption"
                        :formConfig="orderSummaryConfig"></OrderSummary>
                    <BillingAgreementSummary
                        @found-price="updatePrice"
                        :coupon="coupon"
                        :couponCheckPending="couponCheckPending"
                        :couponDisclaimer="couponDisclaimerCopy"
                        @applyCoupon="applyCoupon"
                        @updateOrder="updateOrder"
                        @trialEndDateFormatted="updateTrialEndDate"
                        ref="orderSummary"
                        :recurly="recurly"
                        :annualPlanOption="annualPlanOption"
                        :formConfig="orderSummaryConfig"
                        :promo="this.appliedCouponCode"
                        :couponDiscountBillingDate="getCouponDiscountBillingDate"
                        v-if="enhancedPaymentSummaryEnabled"></BillingAgreementSummary>
                    <PaymentAnnualUpsell
                        v-if="!enhancedPaymentSummaryEnabled"
                        :title="pageAttr.aa_payment_annual_upsell_heading_title"
                        :disclaimer="pageAttr.aa_payment_annual_upsell_disclaimer"
                        :coupon="coupon"></PaymentAnnualUpsell>
                </div>

                <!-- agreement copy: PLOCTOPLUS-1915 center alignment payment page AB TEST, variant B  -->
                <div
                    v-if="!isEditPayment && isCenterAlignPaymentPageModulesAbove"
                    class="order-summary-wrapper payment-layout__summary agreement-copy-module">
                    <p
                        v-if="displayStartCBSButton"
                        v-html="getAgreementCopy()"
                        class="payment-layout__summary__agreement"></p>

                    <input type="hidden" name="recurly-token" data-recurly="token" />

                    <template v-if="needsWaiveOfCoolingRights && displayStartCBSButton">
                        <CheckboxElm
                            data-testid="waiveOfCoolingRighsCheckbox"
                            :checkboxConfig="waiveOfCoolingRighsCheckboxConfigs"
                            :errorMessage="waiveOfCoolingRighsCheckboxErrorMessage"
                            @change="setWaiveOfCoolingRights($event)"></CheckboxElm>
                        <div
                            data-testid="waiveOfCoolingRighsCheckboxError"
                            role="alert"
                            aria-live="polite"
                            class="text-input-error"
                            v-if="waiveOfCoolingRighsCheckboxErrorMessage">
                            {{ waiveOfCoolingRighsCheckboxErrorMessage }}
                        </div>
                    </template>

                    <FormButton
                        v-if="displayStartCBSButton"
                        :buttonConfig="buttonConfig"
                        :buttonState="submitButtonState"
                        @click="onClickSubmit"></FormButton>
                    <button
                        v-else
                        class="payment-layout__summary__submit paypal-checkout qt-paypalbtn"
                        @click="handlePayPalCheckout($event, paypalGateway)">
                        <span class="label">{{ $getLocale('checkout_with') }}</span>
                        <span class="logo" aria-label="PayPal"></span>
                    </button>

                    <div class="payment-layout__status" role="status" aria-live="polite">
                        {{ submitStatus }}
                    </div>
                </div>
                <!-- END OF AB TEST PLOCTOPLUS-1915: center alignment payment page -->

                <!-- edit payment cancel button -->
                <div
                    v-if="isEditPayment"
                    class="grid-item grid-item--row sub-page--actionbar sub-page--actionbar-padded">
                    <template v-if="activeTab === tab.cc">
                        <FormButton
                            :buttonConfig="cancelConfig"
                            @click="cancelOnClicked"></FormButton>
                        <FormButton
                            :buttonConfig="buttonConfig"
                            :buttonState="submitButtonState"
                            @click="onClickSubmit"></FormButton>
                    </template>
                    <template v-else-if="activeTab === tab.payPal">
                        <ButtonCta :buttonConfig="cancelConfig"></ButtonCta>
                        <button
                            class="account-payment-layout__summary__submit paypal-checkout qt-paypalbtn"
                            @click="handlePayPalCheckout($event, paypalGateway)">
                            <span class="paypal-label">
                                {{ $getLocale('switch_to') }}
                            </span>
                            <span class="logo" aria-label="PayPal"></span>
                        </button>
                    </template>
                </div>
            </div>
        </form>
        <transition name="fade">
            <WaitingOverlay
                :line1="getPaymentProcessingMessage"
                :line2="$getLocale('this_will_not_take_long')"
                v-if="waitingOverlayEnabled && formIsProcessing" />
        </transition>
        <div class="threeDSecureModal" v-bind:class="{ open: threeDModalOpen }">
            <div class="backdrop"></div>
            <div class="container" ref="threeDSecure"></div>
        </div>
    </section>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta'; // ~/src/web-package-coreui/src/js/atoms/ButtonCta.vue
    import CheckboxElm from 'atoms/CheckboxElm'; // ~/src/web-package-coreui/src/js/atoms/CheckboxElm.vue
    import CreditCardLogos from 'aa/vue/components/CreditCardLogos'; // ~/src/web-allaccess/src/js/vue/components/CreditCardLogos.vue
    import CustomSelect from 'atoms/CustomSelect'; // ~/src/web-package-coreui/src/js/atoms/CustomSelect.vue
    import FormButton from 'atoms/FormButton'; // ~/src/web-package-coreui/src/js/atoms/FormButton.vue
    import FormFields from 'atoms/FormFields';
    import FormState from 'aa/vue/plugins/FormState';
    import InputElm from 'atoms/InputElm';
    import LabelInput from 'atoms/LabelInput';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import OrderSummary from 'aa/vue/components/OrderSummary';
    import PaymentService from 'aa/vue/services/PaymentService';
    import RecurlyHostedField from 'atoms/RecurlyHostedField';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import WaitingOverlay from 'aa/vue/atoms/WaitingOverlay';
    import modalMixin from 'aa/vue/plugins/modalMixin';
    import { AA_DYNAMIC_AGREEMENT_COPY, AA_PAYPAL } from 'helpers/featureConstants';
    import { ButtonState } from 'models/ButtonConfig';
    import { CreditCardPaymentConfig, OrderSummaryConfig } from 'aa/vue/FormConfig';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';
    import BaseModalWrapper from 'aa/vue/atoms/BaseModalWrapper';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import { DateFormatter, getEndDate } from 'aa/helpers/DateFormatter';
    import { sprintf } from 'sprintf-js';
    import moment from 'moment';
    import PaymentAnnualUpsell from 'aa/vue/components/PaymentAnnualUpsellComponent';
    // START TEST PLOCTOPLUS-1298: google captcha
    import captchaMixin from 'aa/vue/plugins/captchaMixin';
    // END TEST PLOCTOPLUS-1298: google captcha
    import { FLOW_TYPE } from 'models/FlowModel';
    import Domain from 'cbs-core-ui/src/js/helpers/Domain';
    import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';
    import { Tooltip, TooltipConfig } from 'components/Tooltip';
    import BillingAgreementSummary from 'aa/vue/components/BillingAgreementSummary';

    // START TEST PLOCTOPLUS-1915: center align payment page
    import CenterAlignPaymentPageAbTest from 'aa/vue/abTestRelated/CenterAlignPaymentPageAbTest';
    // END TEST PLOCTOPLUS-1915: center align payment page
    import checkoutMixin from 'aa/vue/plugins/checkoutMixin';
    import aarpMixin from 'aa/vue/plugins/aarpMixin';
    import { MULTI_SUB_PLAN_PICKER_ENABLED } from 'aa/helpers/featureConstants';
    import { pluralizeDuration } from 'aa/helpers/stringManipulation';

    // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
    import { UPGRADE_ANNUAL_TOGGLE, UPGRADE_ANNUAL_PLAN_TILES } from 'helpers/featureConstants';
    // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

    const TAB = Object.freeze({
        cc: 'cc',
        payPal: 'payPal',
    });

    export default {
        name: 'SharedPaymentComponent',
        mixins: [
            FormState,
            modalMixin,
            couponsMixin,
            commonMixins,
            pricePrettyPrint,
            // START TEST PLOCTOPLUS-1298: google captcha
            captchaMixin,
            // END TEST PLOCTOPLUS-1298: google captcha
            // START TEST PLOCTOPLUS-1915: center align payment page
            CenterAlignPaymentPageAbTest,
            // END TEST PLOCTOPLUS-1915: center align payment page
            checkoutMixin,
            aarpMixin,
        ],
        components: {
            BaseModalWrapper,
            FormButton,
            FormFields,
            StepsHeader,
            InputElm,
            LabelInput,
            CustomSelect,
            CheckboxElm,
            CreditCardLogos,
            RecurlyHostedField,
            OrderSummary,
            WaitingOverlay,
            ButtonCta,
            PaymentAnnualUpsell,
            BillingAgreementSummary,
        },
        props: {
            isEditPayment: {
                type: Boolean,
                default: false,
            },
            handleProcessedPayment: {
                type: Function,
                default: () => {},
            },
            handleProcessedPaymentError: {
                type: Function,
                default: () => {},
            },
            waitingOverlayEnabled: {
                type: Boolean,
                default: true,
            },
            isFromDirect: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                checkboxConfig: {
                    styleClass: 'cbs-checkbox-input',
                },
                activeTab: TAB.cc,
                annualPlanOption: false,
                enhancedPaymentSummaryEnabled: false,
                payPalToken: null,
                acceptedCards: [],
                countryFormClass: '',
                altPaymentFormView: false,
                paymentRequestData: {},
                bodyScrollPos: 0,
                submitStatus: '',
                orderSummaryConfig: OrderSummaryConfig,

                nflBannerFlag: 'dsp_nfl_game_pass',
                // START TEST PLOCTOPLUS-1298: google captcha
                clickEvent: null,
                captchaAction: 'FORM_PAYMENT',
                // END TEST PLOCTOPLUS-1298: google captcha
                // START TEST PLOCTOPLUS-2033
                tooltip: null,
                // END TEST PLOCTOPLUS-2033
                couponDiscountBillingDate: null,
            };
        },
        computed: {
            currentPlan() {
                if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                    return this.$store.getters['plan/getSelectedPlan'];
                } else {
                    return this.$store.getters['plan/getCurrentPlan'];
                }
            },
            isDomestic() {
                return Domain.isDomestic();
            },
            paypalSuccessMessage() {
                return this.isDomestic
                    ? this.pageAttr.aa_payment_paypal_success_copy
                    : this.pageAttr.billing_summary_paypal_success_copy;
            },
            getPaymentProcessingMessage() {
                return this.isEditPayment
                    ? this.$getLocale('we_are_updating_your_payment_method')
                    : this.$getLocale('we_are_processing_your_subscription');
            },
            hasSkipExplainers() {
                return this.featureIsActive(SKIP_EXPLAINER_STEPS);
            },
            getSubHeading() {
                if (this.hasSkipExplainers) {
                    return this.getSkipExplainersSubHeading;
                } else {
                    if (this.isDomestic) {
                        return this.getDomesticSubHeading;
                    } else {
                        return this.getIntlSubHeading;
                    }
                }
            },
            getPlanTrialEndDateFormatted() {
                return moment(this.plan.trialEndDate).format('MMMM Do YYYY');
            },
            getSkipExplainersSubHeading() {
                let copy =
                    this.isExSubscriber || this.coupon.hasOwnProperty('recurlyInfo')
                        ? this.pageAttr.aa_page_sub_header_exsub
                        : this.pageAttr.aa_page_sub_header;
                let formattedDate = '';
                if (this.trialEndDateFormatted) {
                    formattedDate = moment(this.trialEndDateFormatted).format('MMMM Do YYYY');
                } else {
                    formattedDate = moment(Date.now()).add('7', 'days').format('MMMM Do YYYY');
                }
                return copy.indexOf('%s') >= 0 ? sprintf(copy, formattedDate) : copy;
            },
            getDomesticSubHeading(pageAttrKey) {
                // TODO: remove bundle logic, or delete entirely, or something in between
                let formattedDate = this.getPlanTrialEndDateFormatted,
                    validCoupon =
                        this.appliedCouponCode &&
                        !this.coupon.errorMessage &&
                        !this.couponCheckPending,
                    onlyShowGeneric = this.isGenericCampaign || this.isExSubscriber;

                if (onlyShowGeneric && this.bundleShowtime) {
                    // show showtime generic no matter what
                    return this.pageAttr.explainer_subtitle_generic_showtime;
                } else if (onlyShowGeneric) {
                    //  show generic no matter what
                    return this.pageAttr.explainer_subtitle_generic;
                } else if (validCoupon) {
                    // if valid coupon show correct generic
                    if (this.bundleShowtime) {
                        return this.pageAttr.explainer_subtitle_generic_showtime;
                    } else {
                        return this.pageAttr.explainer_subtitle_generic;
                    }
                } else if (this.bundleShowtime) {
                    // show bundle showtime
                    return sprintf(this.pageAttr.explainer_subtitle_showtime, formattedDate);
                } else {
                    // show normal trial copy
                    return sprintf(this.pageAttr.explainer_subtitle, formattedDate);
                }
            },

            getIntlSubHeading() {
                const { duration, period } = this.getDurationAndPeriod();
                let date = getEndDate(duration, period);

                // Return if coupon is 100% off and new Payment summary is enabled
                if (
                    this.enhancedPaymentSummaryEnabled &&
                    this.coupon?.recurlyInfo?.discount?.type === 'percent' &&
                    this.coupon?.recurlyInfo?.discount?.rate === 1
                ) {
                    const { temporal_unit, temporal_amount } = this.coupon.recurlyInfo;
                    this.calcFullCouponBillingDate(this.coupon, temporal_amount, temporal_unit);
                    return '';
                }

                const isAre = duration === 1 ? 'is' : 'are';

                date = DateFormatter.format(
                    date,
                    {
                        month: 'long',
                    },
                    CBS.Registry.region.dateLocale,
                );

                // return `Your first ${duration} ${period} ${isAre} free, and you won’t be charged until {DATE}. Cancel anytime.`
                //.replace("{DATE}", date)
                if (this.coupon?.trialString || this.plan?.trialString) {
                    let explainerLine1 = '';
                    if (this.pageAttr.explainer_line_1) {
                        explainerLine1 = this.pageAttr.explainer_line_1
                            ?.replace('{duration}', duration)
                            ?.replace('{period}', this.$getLocale(period))
                            ?.replace('{isAre}', isAre)
                            ?.replace('{date}', date);

                        // Support legacy format while the CMS still has %s for the date in this string
                        if (explainerLine1.indexOf('%s') >= 0) {
                            return sprintf(explainerLine1, date);
                        }
                    }
                } else {
                    const dateStr = moment(new Date()).add('7', 'days').format('MM/DD/YYYY');
                    return this.pageAttr.aa_page_sub_header.replace('{DATE}', dateStr);
                }
            },

            paypalGateway() {
                if (this.featurePaypal) {
                    if (this.$store.state.serverData?.appMode) {
                        return this.$store.state.serverData.paymentMethods.paypal?.gateway?.default
                            ? this.$store.state.serverData.paymentMethods.paypal?.gateway.default[
                                  this.$store.state.serverData?.appMode
                              ]
                            : false;
                    }
                    return false;
                }
                return false;
            },
            showStepsHeader() {
                if (this.$store.state.serverData.hideStepsHeader) {
                    return false;
                }

                return !this.isEditPayment && !this.hasSkipExplainers;
            },
            showPaymentAnnualUpsell() {
                return (
                    !this.$store.state.serverData?.multiSubPlanPicker &&
                    !this.pageAttr.hide_annual_upsell_on_payment &&
                    !this.isGiftFlow &&
                    !this.isEdu &&
                    !this.isSuperFunnel
                );
            },
            getStepTitle() {
                if (this.hasSkipExplainers) {
                    return this.pageAttr.aa_page_header;
                } else {
                    return this.pageAttr.explainer_description;
                }
            },

            displayStartCBSButton() {
                return this.activeTab === TAB.cc || this.payPalToken;
            },

            formConfig() {
                return CreditCardPaymentConfig;
            },

            cancelConfig() {
                return {
                    type: 'button',
                    styleClass: ['button', 'secondary'],
                    displayText: this.$getLocale('cancel'),
                    event: this.cancelOnClicked,
                };
            },

            tabIndicatorClass() {
                return [
                    'active-indicator',
                    {
                        'active-indicator--payPal': this.activeTab === TAB.payPal,
                    },
                    { '-no-steps-header': !this.showStepsHeader },
                ];
            },

            tab() {
                return TAB;
            },

            isGenericCampaign() {
                return this.pageAttr.use_generic_campaign_explainer_set;
            },

            ...mapState('user', {
                isExSubscriber: (state) => state.isExSubscriber,
            }),

            ...mapState({
                promo: (state) => state.promo,
            }),

            ...mapState('authSuite', {
                accessStatus: (state) => state.accessStatus,
            }),

            ...mapGetters('flow', {
                flowType: 'type',
                isNoFreeTrialCampaign: 'isNoFreeTrialCampaign',
                isCampaign: 'isCampaign',
                isGiftFlow: 'isGift',
                isEdu: 'isEdu',
            }),

            language() {
                return this.isEditPayment ? '' : this.$store.state.serverData.language;
            },

            getCouponDiscountBillingDate() {
                return this.couponDiscountBillingDate;
            },
        },

        methods: {
            ...mapActions('payment', ['updateShortCode']),
            // START TEST PLOCTOPLUS-2033
            handleRecurlyFocus() {
                if (this.tooltip) {
                    this.tooltip.hide(new Event('click'));
                }
            },
            // END TEST PLOCTOPLUS-2033

            getFieldLabel: function (fieldName) {
                // TODO: move to mixin SubFlowForm
                if (this.pageAttr[this.formName + '_' + fieldName.toLowerCase()]) {
                    return this.pageAttr[this.formName + '_' + fieldName.toLowerCase()];
                }
            },

            getDurationAndPeriod() {
                let duration = 7;
                let period = 'days';

                if (this.coupon?.recurlyInfo?.discount?.trial) {
                    const { amount, unit } = this.coupon.recurlyInfo.discount.trial;
                    duration = amount;
                    period = unit;
                    period = pluralizeDuration(duration, period);
                }

                return { duration, period };
            },

            handleEnterKey(e) {
                e.target.click();
            },

            handleClickTab(e, tabId) {
                const trackingService = this.isEditPayment ? this.$trackingService : tracking;
                e.preventDefault();
                if (tabId === TAB.cc) {
                    this.activeTab = TAB.cc;
                } else {
                    this.activeTab = TAB.payPal;
                }
                trackingService.trackAction(ACTION_NAMES.PAYMENT_METHOD_SELECTED, {
                    ctaText: this.activeTab,
                    pageType: !this.isEditPayment ? 'svod_payment' : 'account_switchpayment',
                });
            },

            cancelOnClicked() {
                tracking.trackAction(ACTION_NAMES.CREDIT_CARD_CANCEL);
                this.$router.push({ name: 'AccountMain' });
            },

            resubmitPayment(token) {
                const payload = {
                    ...this.paymentRequestData,
                    resultActionTokenId: token.id,
                };
                const httpData = this.isEditPayment ? this.buildHttpData(payload) : payload;
                this.sendPayment(httpData);
            },

            submitEditPayment(token) {
                const payload = {
                    token: token.id,
                };

                // START TEST PLOCTOPLUS-1298: google captcha
                if (this.formData?.recaptchaToken) {
                    payload.recaptchaToken = this.formData.recaptchaToken;
                }
                // END TEST PLOCTOPLUS-1298: google captcha

                this.paymentRequestData = payload;
                const httpData = this.buildHttpData(payload);
                this.sendPayment(httpData);
            },

            submitPayment(token, buttonEvent) {
                this.formErrorMessage = null;
                this.formIsProcessing = true;

                let couponCode = null;
                if (this.coupon && this.coupon.recurlyInfo && this.coupon.recurlyInfo.code) {
                    couponCode = this.coupon.recurlyInfo.code;
                }

                let payload = {
                    token: token,
                    buttonEvent: buttonEvent,
                    plan: this.currentPlan,
                    couponCode: couponCode,
                    tk_trp: this.$store.state.token,
                };

                if (this.flowType === FLOW_TYPE.AARP) {
                    let aarpUserId = { aarpUserId: this.getAarpUserId() };
                    Object.assign(payload, aarpUserId);
                }

                // START TEST PLOCTOPLUS-1298: google captcha
                if (this.formData?.recaptchaToken) {
                    payload.recaptchaToken = this.formData.recaptchaToken;
                }
                // END TEST PLOCTOPLUS-1298: google captcha

                // Save payload for retry if needed
                this.paymentRequestData = payload;

                this.isEditPayment && tracking.trackAction(ACTION_NAMES.CREDIT_CARD_SUBMIT_UPDATE);
                this.sendPayment(payload);
            },

            sendPayment(payload) {
                this.formIsProcessing = true;

                const couponCode = payload.couponCode;
                if ('URLSearchParams' in window) {
                    if (location.search) {
                        const searchParams = new URLSearchParams(location.search.substr(1));
                        const gatewayOverride = searchParams.get('gateway');
                        if (gatewayOverride) {
                            payload.gateway = gatewayOverride;
                        }
                    }
                }
                const processSelectedMethod = this.isEditPayment
                    ? this.processEditPayment
                    : this.processPayment;
                processSelectedMethod(payload)
                    .then((resp) => {
                        // START TEST PLOCTOPLUS-1298: google captcha
                        this.trackCaptchaTokenValidationResult(resp);
                        // END TEST PLOCTOPLUS-1298: google captcha
                        this.handleProcessedPayment(resp);
                        if (this.isEditPayment) {
                            this.$trackingService.trackAction(ACTION_NAMES.UPDATE_PAYPAL_SUCCESS, {
                                pageType: 'account_switchpayment',
                            });
                        }
                    })
                    .catch((err) => {
                        // START TEST PLOCTOPLUS-1298: google captcha
                        this.trackCaptchaTokenValidationResult(err);
                        // END TEST PLOCTOPLUS-1298: google captcha
                        let trackingParams = {};
                        if (this.activeTab === TAB.payPal) {
                            const { planType } = this.plan;
                            trackingParams.purchasePaymentMethod = this.activeTab;
                            trackingParams.productPricingPlan = planType;
                        }
                        this.handleProcessedPaymentError(err, trackingParams);
                    })
                    .finally(() => {
                        this.formIsProcessing = false;
                    });
            },

            setUpThreeDSecure(actionTokenId) {
                if (actionTokenId) {
                    const risk = this.recurly.Risk();

                    const threeDSecure = risk.ThreeDSecure({ actionTokenId });
                    threeDSecure.on('error', (err) => {
                        this.formErrorMessage = this.$getLocale(
                            'recurly_PSD2_error_please_try_again',
                        );
                        this.closeThreeDModal();
                        threeDSecure.remove();
                    });
                    threeDSecure.on('token', (token) => {
                        this.resubmitPayment(token);
                        this.closeThreeDModal();
                        threeDSecure.remove();
                    });
                    this.openThreeDModal();
                    threeDSecure.attach(this.$refs.threeDSecure);
                }
            },

            CBSSportsUpdateShortCode() {
                if (this.$store.getters['flow/type'] === FLOW_TYPE.CBS_SPORTS) {
                    let payload = {
                        tk_trp: this.$store.state.token,
                        queryString: sessionStorage.getItem('oauth-cbssports-client-params'),
                    };
                    this.updateShortCode(payload)
                        .then((res) => {
                            // ignore response
                        })
                        .catch((err) => {
                            // ignore errors
                        })
                        .finally(() => {
                            sessionStorage.removeItem('oauth-cbssports-client-params');
                        });
                }
            },
        },

        // Lifecycle Hooks
        mounted() {
            this.enhancedPaymentSummaryEnabled =
                this.$store.state.serverData.enhancedPaymentSummaryEnabled;
            if (this.isEditPayment) {
                this.captchaAction = 'FORM_EDIT_PAYMENT';
            }

            this.addCaptchaValidation();

            // START AB TEST PLOCTOPLUS-2033
            if (this.$store.getters['variant/isPaymentHypertextLink']) {
                this.tooltip = new Tooltip(
                    new TooltipConfig({
                        events: ['mouseover', 'click'],
                        selectorsList: ['.cc-hyperlink a', '.cc-checkmark-lock-icon'],
                        targetSelector: '.cc-checkmark-lock-icon',
                        sameWidthAsSelector: {
                            mobilePortrait: '.field-wrapper--cc-number',
                            mobileLandscape: '.field-wrapper--cc-number',
                            tabletPortrait: '.field-wrapper--cc-number',
                        },
                        innerHtmlTargetSelector: {
                            mobilePortrait: '.field-wrapper--cc-number',
                            mobileLandscape: '.field-wrapper--cc-number',
                            tabletPortrait: '.field-wrapper--cc-number',
                        },
                        innerHTML:
                            'We use SSL to encrypt your payment information while your payment is being processed. Paramount+ does not store your credit card information.',
                        trackOnShow: (event) => {
                            if (event && event.target) {
                                if (
                                    event.type === 'mouseover' &&
                                    event.target.id === 'lock-icon-hyperlink'
                                ) {
                                    tracking.trackAction('trackPaymentLearnMoreButtonHover');
                                } else if (
                                    event.type === 'mouseover' &&
                                    event.target.id === 'cc-lock-icon'
                                ) {
                                    tracking.trackAction('trackPaymentLockIconHover');
                                }
                            }
                        },
                    }),
                );
            }
            // END AB TEST PLOCTOPLUS-2033
        },

        created() {
            this.countryFormClass = `form-${this.registrationCountry}`;
            this.altPaymentFormView = this.$store.state.serverData.altPaymentFormView;
            this.acceptedCards = this.$store.state.serverData.paymentMethods?.acceptedCreditCards;
            this.featurePaypal = this.$store.state.serverData.paymentMethods?.paypal.enabled;
            this.featureFlagPaypal = this.$store.state.serverData?.paypalAvailableInCountry;
            this.annualPlanOption = this.$store.state.serverData.annualPlanOption;

            let inputList = this.$store.state.serverData.paymentForm;

            let { recurlyFieldConfig } = CreditCardPaymentConfig;

            let fieldConfigs = {
                ...inputList,
                ...recurlyFieldConfig,
            };

            fieldConfigs.first_name.value = this.$store.getters['user/firstName'];
            fieldConfigs.last_name.value = this.$store.getters['user/lastName'];

            this.fieldConfigs = fieldConfigs;
            this.setValidationService();

            this.subscriberFlag =
                (this.$store.getters['user/isExSubscriber'] ? 'Ex' : 'New') + ' Subscriber';

            //START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            if (this.$store.getters['variant/isAnnualUpsellPaymentPageToggle']) {
                this.$store.commit('featureFlags/enableFeature', UPGRADE_ANNUAL_TOGGLE);
            }

            if (this.$store.getters['variant/isAnnualUpsellPaymentPageTwoPlanTiles']) {
                this.$store.commit('featureFlags/enableFeature', UPGRADE_ANNUAL_PLAN_TILES);
            }
            // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

            if (this.isFromDirect && this.$route.params?.directLinkFlow) {
                const trackingParams = {
                    productPricingPlan: this.plan.planType,
                    pickPlanType: this.plan.planTier,
                    pickPlanSku: this.plan.recurlyCode,
                    productNewSku: this.plan.recurlyCode,
                    purchaseEventBillingStart: '1',
                    pageType: `winback_${this.$route.params.directLinkFlow}_directLink_payment`,
                    pageUrl: window.location.href,
                    purchaseProduct: this.plan.recurlyCode,
                    purchaseProductName: this.plan.planTitle,
                    purchasePrice: this.plan.rawPrice,
                    productOfferPeriod: this.plan?.trialString ?? '',
                    purchaseQuantity: '1',
                    purchasePromoCode: this.promo,
                };

                tracking.trackState(trackingParams);
            }
        },
    };
</script>
