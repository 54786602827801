import Domain from 'helpers/Domain';
import Plan from 'aa/vue/models/Plan';
import Product from 'aa/vue/models/Product';
import PlanService from 'aa/vue/services/MultiSubPlanService';
import CheckoutOptions from 'aa/vue/models/CheckoutOptions';
import { DateFormatter } from 'aa/helpers/DateFormatter';

import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
import { getLocale } from 'services/LocalizationService';
import {
    SWITCH_DIRECTION_UPGRADE,
    SWITCH_DIRECTION_DOWNGRADE,
    SWITCH_DIRECTION_DISABLED,
    SWITCH_DIRECTION_CURRENT,
} from 'aa/vue/constants/shared';

export function mapValues(key, value, plan, localizedPlanTitles) {
    switch (key) {
        case 'plan_currency':
            value = value.replace(/{subscriptionCurrency}/g, plan.currency);
            break;
        case 'plan_period':
            value = value.replace(/\p{L}+/gu, plan.priceUnit);
            break;
        case 'plan_price':
            value = value.replace(
                /{subscriptionPrice}/g,
                pricePrettyPrint.methods.pricePrettyPrint(
                    plan.rawPrice,
                    plan.currencySymbol,
                    plan.currency,
                    CBS.Registry.region.locale,
                    false,
                ),
            );
            break;
        case 'sub_heading':
            value = value.replace(
                /{plan}/g,
                `<span class="multi-sub-plan__highlight">${localizedPlanTitles[plan.planTier]}</span>`,
            ); // Mocked Values
        case 'plan_description':
            value = value.replace(/{subscriptionPeriod}/g, getLocale('month'));
            value = value.replace(
                /{calculatedMonthlyPrice}/g,
                pricePrettyPrint.methods.pricePrettyPrint(
                    parseFloat(plan.rawPrice.replace(/,/g, '')) / 12,
                    plan.currencySymbol,
                    plan.currency,
                    CBS.Registry.region.locale,
                    false,
                ),
            );
            break;
        default:
        // do nothing
    }

    return value;
}

export function isUpgrade(plan) {
    if (plan?.switchDirection?.toLowerCase() === SWITCH_DIRECTION_UPGRADE) {
        return true;
    }
    return false;
}

export function isDowngrade(plan) {
    if (plan?.switchDirection?.toLowerCase() === SWITCH_DIRECTION_DOWNGRADE) {
        return true;
    }
    return false;
}

// Option to pass in isUpgrade and isDowngrade is used in tests
export function getCadencePlanOptions(
    cadenceData,
    isUpgradeFunc = isUpgrade,
    isDowngradeFunc = isDowngrade,
) {
    let { cadence, planType, plans, pageAttributes, getters } = cadenceData;
    const matchingPlan = plans.find((plan) => plan.planTier === cadence.planTier);
    if (matchingPlan === undefined) {
        return false;
    }
    let options = { planType };
    if (matchingPlan) {
        Object.entries(cadence).forEach((item) => {
            let [key, value] = item;
            if (cadence.learn_more_pill) {
                const legal = {
                    learn_more_pill: cadence.learn_more_pill,
                    learn_more_copy: cadence.learn_more_copy,
                    legal_copy_desktop: cadence.legal_copy_desktop,
                    legal_copy_header: cadence.legal_copy_header,
                    legal_copy_mobile: cadence.legal_copy_mobile,
                    legal_copy_ott: cadence.legal_copy_ott,
                };

                matchingPlan = {
                    ...matchingPlan,
                    ...legal,
                };
            }
            if (key.includes(planType)) {
                key = key.replace(planType + '_', '');
                if (key === 'plan_price_savings') {
                    key = 'plan_pill';
                }
                options[key] = mapValues(key, value, matchingPlan);
            } else {
                pageAttributes[key] = mapValues(
                    key,
                    value,
                    matchingPlan,
                    getters['getLocalizedPlanTitles'],
                );
            }

            options.plan = matchingPlan;
            options.plan.next_billing_date = getNextBillingDate(options.plan, getters);
        });
        const currentPlan = getters['currentPlan'];

        if (currentPlan) {
            const currentPlanValues = {
                plan_is_current: true,
                current_plan_submit_button: cadence.current_plan_submit_button,
                current_plan: cadence.current_plan,
            };

            const planFound =
                currentPlan.plan_type === matchingPlan.planType &&
                currentPlan.plan_tier === matchingPlan.planTier;

            options.isUpgrade = isUpgradeFunc(matchingPlan);
            options.isDowngrade = isDowngradeFunc(matchingPlan);
            options = planFound ? { ...options, ...currentPlanValues } : options;
        }
        cadence = pageAttributes;
        cadence.plans = [options];
    }

    return { options, pageAttributes };
}

export function getNextBillingDate(plan, getters) {
    const currentSubscription = getters['getCurrentSubscription'];

    // If the plan is a downgrade, return the current subscription's next billing date
    if (
        currentSubscription &&
        plan?.switchDirection?.toLowerCase() === SWITCH_DIRECTION_DOWNGRADE
    ) {
        return currentSubscription?.next_bill_date_str;
    } else {
        const trialEndDate = plan.trial ? new Date(plan.trialEndDate) : new Date();
        const trialEndDateFormatted = DateFormatter.format(
            trialEndDate,
            null,
            CBS.Registry.region.dateLocale,
        );
        return trialEndDateFormatted;
    }
}

export function getPlanLabelKey(rootGetters, getters, planTier, planType) {
    const annualPlanOption = rootGetters.getServerDataByKey('annualPlanOption');
    const isMSPPickerEnabled = rootGetters.getServerDataByKey('multiSubPlanPicker');
    const planCases = {
        ads: '_basic_with_ads',
        annual: '_annual',
        monthly: '_monthly',
        mobile: '_basic',
        standard: '_standard',
        premium: '_premium',
    };
    const planTypeValue = isMSPPickerEnabled && !annualPlanOption ? Product.TYPE_MONTHLY : planType;
    // only if MSPPicker and annualPlanOption are false we need an empty string;
    const planTypeChunk =
        !isMSPPickerEnabled && !annualPlanOption ? '' : (planCases[planTypeValue] ?? '');
    const planTierChunk =
        isMSPPickerEnabled && !getters.skipTier ? (planCases[planTier] ?? '') : '';

    return `paramount_plus${planTypeChunk}${planTierChunk}`;
}

export default {
    namespaced: true,

    state: {
        activePlan: null, // This plan are the selections in action including pre-selection
        addonShowtime: false,
        cadences: [],
        currentPlan: null, // The plan the user currently has
        disabledTiers: [],
        expectedPlans: [],
        legals: [],
        localizedPlanTitles: [],
        pickerPlanSelected: false,
        plans: [],
        planTier: null,
        planType: Product.TYPE_MONTHLY /* planType: String - key of the active cadence */,
        promoFields: [],
        selectedPlan: null, // The plan the user has selected
        switchPlan: false,
        tiers: [],
        tiersWithValidPromos: [],
        switchToAnnual: null,
        upcomingPlan: null,
        validPromoExists: false,
    },

    // commit and track state changes
    mutations: {
        setPickerPlanSelected(state) {
            state.pickerPlanSelected = true;
        },

        setSwitchPlan(state) {
            state.switchPlan = true;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlans(state, payload) {
            this.__clearPlans(state);
            state.plans = payload;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setTiers(state, payload) {
            state.tiers = payload;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setLocalizedPlanTitles(state, payload) {
            state.localizedPlanTitles = { ...state.localizedPlanTitles, ...payload };
        },

        /**
         *
         * @param state
         * @param payload
         */
        setCadences(state, payload) {
            state.cadences = payload;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setLegals(state, payload) {
            state.legals = payload;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPromoFields(state, payload) {
            state.promoFields = payload;
        },

        // /**
        //  *
        //  * @param state
        //  * @param payload
        //  */
        setDisabledTiers(state, payload) {
            state.disabledTiers = payload;
        },

        setSwitchToAnnual(state, payload) {
            state.switchToAnnual = payload;
            sessionStorage.setItem('switchToAnnual', JSON.stringify(payload));
        },

        /**
         *
         * @param state
         * @param payload
         */
        setShared(state, payload) {
            state.shared = payload;
        },

        /**
         *App.js
         * @param state
         * @param payload
         */
        setPlanTier(state, planTier) {
            state.planTier = planTier;
            sessionStorage.setItem('planTier', JSON.stringify(state.planTier));
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlanType(state, planType) {
            state.planType = Domain.isDomestic() ? planType : planType.planType;
            sessionStorage.setItem('planType', JSON.stringify(state.planType));
        },

        setSelectedPlan(state, selectedPlan) {
            state.plans.forEach((plan) => {
                plan.isActive =
                    plan.planTier === selectedPlan.plan_tier &&
                    plan.planType === selectedPlan.plan_type;
                plan.isCurrent =
                    plan.planTier === selectedPlan.plan_tier &&
                    plan.planType === selectedPlan.plan_type;

                plan.isUpgrade = isUpgrade(plan);
            });

            state.selectedPlan = selectedPlan;
        },

        setActivePlan(state, activePlan) {
            state.activePlan = activePlan;
        },

        setCurrentPlan(state, currentPlan) {
            state.currentPlan = currentPlan;
        },

        setUpcomingPlan(state, upcomingPlanSku) {
            const upcomingPlans = state.plans.filter((plan) => {
                return plan.recurlyCode === upcomingPlanSku;
            });

            state.upcomingPlan = upcomingPlans.length ? upcomingPlans[0] : null;
        },

        /**
         * Private, will clear the existing array of plans when XHR call returns.
         * The XHR call should only occur if the currentPlanType is null or there are no plans in the monthly or annual bucket
         * When user state changes currentPlanType should be reset to null (or need to come up with another flag)
         * @param state
         */
        __clearPlans(state) {
            state.plans = [];
        },

        /**
         * Private, called when XHR to get plans returns
         * @param state
         * @param payload
         */
        __appendPlan(state, payload) {
            payload.couponString = '';
            payload.isValidPromo = false;
            state.plans.push(payload);
        },

        setAddonShowtime(state, addonShowtime) {
            state.addonShowtime = Boolean(addonShowtime);
        },

        /**
         *
         * @param state
         * @param payload
         */
        updatePlanCouponString(state, payload) {
            const plans = [...state.plans];

            plans.map((plan) => {
                if (plan.recurlyCode === payload.recurlyCode) {
                    plan.couponString = payload.couponString;
                    plan.isValidPromo = true;
                    state.tiersWithValidPromos = [...state.tiersWithValidPromos, payload.planTier];
                    state.validPromoExists = true;
                }
            });

            state.plans = plans;
        },

        /**
         * @param state
         * @param payload
         */
        setSelectedPlanWithTypeandTier(state, payload) {
            const selectedPlan = state.plans.find((plan) => {
                return plan.tier === payload.planTier && plan.planType === payload.planType;
            });

            state.selectedPlan = selectedPlan;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlanCouponString(state, { recurlyCode, couponString, couponType }) {
            if (!recurlyCode) {
                return;
            }

            const plan = state.plans.find((plan) => {
                return plan.recurlyCode === recurlyCode;
            });

            if (plan) {
                plan.couponString = couponString || null;
                plan.couponType = couponType || null;
            }
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlanCoupon(state, payload) {
            if (!payload.recurlyCode) {
                return;
            }

            const plan = state.plans.find((plan) => {
                return plan.recurlyCode === payload.recurlyCode;
            });

            if (plan) {
                plan.setCoupon(payload);
            }
        },
    },

    // updates the state
    // actions call mutations, which update state
    actions: {
        /**
         *
         * @param context
         * @param payload
         */
        setPlans(context, payload) {
            context.dispatch('__clearPlans');
            payload.forEach((obj) => {
                obj.isActive = false;
                context.dispatch('__appendPlan', obj);
            });
            context.dispatch('__setDisabledTiers', payload);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setAttributes({ dispatch, commit }, payload) {
            dispatch('__setTiers', payload.tiers);
            dispatch('__setCadences', payload.cadences);
            dispatch('__setLegals', payload.legals); // This prepares legal overlays and maybe the Enhanced Order Summary
            dispatch('__setPromoFields', payload.promoFields);
            commit('setShared', payload.shared);
        },

        /**
         * The goal of this method is to populate the tiers array with data needed to populate the MultiSubPlanBoxes
         *
         * @param context - is the store
         * @param payload - is the page attributes of tier data
         */
        __setTiers({ commit, getters }, payload) {
            let tiersArray = Array(payload.length);
            /* Consider re-doing the mapping so you iterate over the tiers according to regionalized instead of the page attributes */
            payload.forEach((obj) => {
                obj.planTier = obj.tags.filter((tag) => tag !== 'plan')[0];
                obj.isActive = false;

                const matchingPlan = getters['getPlans'].find(
                    (plan) => plan.planTier === obj.planTier,
                );
                /* matching plan is you found a match between page attribute tiers and regionalized tiers */
                const currentPlan = getters['currentPlan'];

                commit('setLocalizedPlanTitles', { [obj.planTier]: obj.plan_title });
                if (matchingPlan) {
                    Object.entries(obj).forEach((item) => {
                        let [key, value] = item;
                        obj[key] = mapValues(key, value, matchingPlan);
                    });

                    obj.plan = matchingPlan;

                    if (currentPlan) {
                        //If the matchedPlan is the current plan add extra values
                        const currentTier = currentPlan.plan_tier === matchingPlan.planTier;
                        if (currentTier) {
                            const currentPlanValues = {
                                plan_is_current: true,
                            };
                            obj = { ...obj, ...currentPlanValues };
                        }
                    }
                    let planPosition = Number(obj.plan_position) - 1;
                    let inserted = false;

                    while (!inserted) {
                        if (tiersArray?.[planPosition]) {
                            planPosition++;
                        } else {
                            tiersArray.splice(planPosition, 1, obj);
                            inserted = true;
                        }
                    }
                } else {
                    // TODO handle error
                    let placeHolder = '';
                    const errorData = {
                        userRegistrationCountry: CBS.Registry.user?.country,
                        locale: CBS.Registry.region.locale,
                        pageAttributeTier: obj.planTier,
                        regionalizedTier: placeHolder,
                        url: window.location.href,
                    };
                }
            });
            commit(
                'setTiers',
                tiersArray.filter((el) => el !== null),
            );
        },

        /**
         * The goal of this method is to populate the cadences array with data needed to populate the MultiSubPlanBoxes
         *
         * @param context - is the store
         * @param payload - is the page attributes of cadence data
         */
        __setCadences({ commit, getters }, payload) {
            const cadencesArray = Array();
            payload.forEach((cadence) => {
                cadence.planTier = cadence.tags.filter((tag) => tag !== 'billing')[0];
                cadence.isActive = false;
                const pageAttributes = {};
                const monthlyPlan = getCadencePlanOptions({
                    cadence,
                    planType: Product.TYPE_MONTHLY,
                    plans: getters['monthlyPlans'],
                    pageAttributes,
                    getters,
                });
                const annualPlan = getCadencePlanOptions({
                    cadence,
                    planType: Product.TYPE_ANNUAL,
                    plans: getters['annualPlans'],
                    pageAttributes,
                    getters,
                });
                if (!annualPlan && !monthlyPlan) {
                    return;
                }
                cadence = monthlyPlan.pageAttributes ?? annualPlan.pageAttributes;
                cadence.plans = [];
                monthlyPlan.options && cadence.plans.push(monthlyPlan.options);
                annualPlan.options && cadence.plans.push(annualPlan.options);

                cadencesArray.push(cadence);
            });

            commit(
                'setCadences',
                cadencesArray.filter((cadence) => !(cadence && Object.keys(cadence).length === 0)),
            );
        },

        /**
         *
         * @param context
         * @param payload
         */
        __setLegals({ commit, getters, rootGetters }, payload) {
            commit('setLegals', payload);
        },

        /**
         *
         * @param context
         * @param payload
         */
        __setPromoFields({ commit, getters, rootGetters }, payload) {
            commit('setPromoFields', payload);
        },

        /**
         *
         * @param context
         * @param plans
         */
        __setDisabledTiers({ commit, getters, rootGetters }, plans) {
            const disabledTiers = [];
            const planTiers = [...new Set(plans.map((plan) => plan.planTier))];
            const disabledSwitches = [SWITCH_DIRECTION_DISABLED, SWITCH_DIRECTION_CURRENT];

            for (const planTier of planTiers) {
                const canSwitchPlans = plans
                    .filter((plan) => !plan.trial && plan.planTier === planTier)
                    .some((plan) => !disabledSwitches.includes(plan.switchDirection));

                if (!canSwitchPlans) {
                    disabledTiers.push(planTier);
                }
            }

            commit('setDisabledTiers', disabledTiers);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setSwitchToAnnual({ commit, getters, rootGetters }, payload) {
            commit('setSwitchToAnnual', payload);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setPlanType(context, payload) {
            context.commit('setPlanType', payload);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setPlanTier(context, payload) {
            context.commit('setPlanTier', payload);
        },

        /**
         *
         * @param context
         * @private
         */
        __clearPlans(context) {
            context.commit('__clearPlans');
        },

        /**
         *
         * @param context
         * @param payload
         * @private
         */
        __appendPlan(context, payload) {
            if (payload) {
                context.commit('__appendPlan', payload);
            }
        },

        /**
         *
         * @param context
         * @param getters
         * @returns {Promise<any>}
         */
        getPlans({ context, getters }) {
            let self = this;

            return new Promise(function (resolve, reject) {
                let hasPlans = getters['hasPlans'];
                if (hasPlans) {
                    resolve(getters['getPlans']);
                } else {
                    PlanService.getPlans()
                        .then((res) => {
                            self.dispatch('plan/setPlans', res);
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },

        /**
         *
         * @param context
         * @param payload
         */
        setSelectedPlan(context, payload) {
            context.commit('setSelectedPlan', payload);
            context.commit('setPlanType', { planType: payload.plan_type });
            context.commit('setPlanTier', payload.plan_tier);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setActivePlan(context, payload) {
            context.commit('setActivePlan', payload);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setCurrentPlan(context, payload) {
            context.commit('setCurrentPlan', payload);
        },

        updatePlanCouponString(context, payload) {
            Domain.isDomestic()
                ? context.dispatch('usUpdatePlanCouponString', payload)
                : context.dispatch('intlUpdatePlanCouponString', payload);
        },

        /**
         * Update the trial copy when an URL coupon is being used
         * @param context
         * @param payload
         */
        intlUpdatePlanCouponString({ commit, getters }, payload) {
            if (payload) {
                let newPayload = payload;
                commit('updatePlanCouponString', newPayload);
            }
        },

        /**
         * Update the trial copy when an URL coupon is being used
         * @param commit
         * @param getters
         * @param recurlyCode
         * @param couponString
         * @param couponType
         */
        usUpdatePlanCouponString({ commit, getters }, { recurlyCode, couponString, couponType }) {
            if (recurlyCode) {
                commit('setPlanCouponString', {
                    recurlyCode,
                    couponString,
                    couponType,
                });
            }
        },

        /**
         *
         * @param context
         * @param payload
         */
        updatePlans({ commit }, payload) {
            let plans = [];
            if (Array.isArray(payload)) {
                plans = payload.map((obj) => {
                    return new Plan(obj);
                });
            }
            commit('setPlans', plans);
        },

        updateAddonShowtime({ commit, rootState }, addonShowtime) {
            commit('setAddonShowtime', addonShowtime);

            CheckoutOptions.get().save({
                addonShowtime: addonShowtime,
                userID: rootState.user?.regID,
            });
        },

        filterPlans({ commit }, payload) {
            if (payload.planType) {
                commit('setPlanType', payload.planType);
            }
        },

        /**
         *
         * @param context
         * @param getters
         * @returns {Promise<any>}
         */
        fetchPlans({ getters, dispatch }) {
            return new Promise(function (resolve, reject) {
                let hasPlans = getters['hasPlans'];
                if (hasPlans) {
                    resolve(getters['getPlans']);
                } else {
                    PlanService.getPlans()
                        .then((plans) => {
                            dispatch('updatePlans', plans);
                            resolve(plans);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },

        /**
         *
         * @param context
         * @param plan
         */
        updateSelectedPlan(context, plan) {
            context.commit('setSelectedPlan', plan);
        },

        /**
         *
         * @param context
         * @param planType
         * @param planTier
         */
        updateSelectedPlanWithTypeandTier(context, payload) {
            context.commit('setSelectedPlanWithTypeandTier', payload);
        },

        loadStore({ commit, dispatch }, payload) {
            if (payload && typeof payload === 'object') {
                commit('setPlanType', payload.planType);

                const savedOptions = CheckoutOptions.get();
                commit('setAddonShowtime', savedOptions.addonShowtime);

                dispatch('updatePlans', payload.plans || []);
            }
        },

        async updatePricingInfo({ dispatch }, { plan, coupon }) {
            if (coupon) {
                await dispatch('updatePricingInfoWithCoupon', { plan, coupon });
            }
        },

        async updatePricingInfoWithCoupon({ dispatch, rootState, rootGetters }, { plan, coupon }) {
            if (typeof plan.couponIsValid === 'boolean' || rootGetters['flow/isGift']) {
                return;
            }

            rootState.payment.recurly.configure({
                publicKey: rootState.payment.recurlyPublicKey,
                cors: true,
            });

            let pricing = rootState.payment.recurly.Pricing();
            let couponConfig, couponPrice;

            const couponPromise = new Promise((resolve, reject) => {
                pricing.on('set.coupon', (coupon) => {
                    // only change trial copy if the coupon type is not percent
                    dispatch('payment/processCoupon', coupon, { root: true })
                        .then(({ data }) => {
                            resolve({
                                recurlyCode: plan.recurlyCode,
                                couponString: data.coupon?.string.standard,
                                couponIsValid: true,
                                recurlyCoupon: coupon,
                                couponRemovesTrial: !data.trialEligible,
                                couponDiscount: coupon?.discount,
                                applies_for_months: coupon?.applies_for_months,
                            });
                        })
                        .catch((e) => {
                            // console.error(e)
                            resolve({});
                        });
                });

                pricing.on('error.coupon', (coupon) => {
                    resolve({
                        recurlyCode: plan.recurlyCode,
                        couponIsValid: false,
                    });
                });
            }).then((result) => {
                couponConfig = result;
            });

            const pricePromise = new Promise((resolve, reject) => {
                pricing
                    .plan(plan.recurlyCode)
                    .coupon(coupon)
                    .catch((e) => {
                        // console.error(e)
                    })
                    .done((price) => {
                        resolve({
                            recurlyPrice: price,
                            recurlyCouponSubtotal: new Intl.NumberFormat(
                                CBS.Registry.region.locale,
                                {
                                    style: 'currency',
                                    currency: price.currency.code,
                                },
                            ).format(price.next.subtotal),
                        });
                    });
            }).then((result) => {
                couponPrice = result;
            });

            await Promise.allSettled([couponPromise, pricePromise]);
            await dispatch('updatePlanCoupon', Object.assign(couponConfig, couponPrice));
        },
    },

    // access the state
    getters: {
        getPlans: (state) => {
            try {
                let plans = state.plans.filter((obj) => {
                    return obj.planType === state.planType;
                });

                return plans;
            } catch (e) {
                return [];
            }
        },

        getTiers: (state) => {
            return state.tiers;
        },

        getLegals: (state) => {
            return state.legals;
        },

        getPromoFields: (state) => {
            return state.promoFields;
        },

        getPlanTier: (state) => {
            return state.planTier;
        },

        getLocalizedPlanTitles: (state) => {
            return state.localizedPlanTitles;
        },

        getPlanType: (state) => {
            return state.planType;
        },

        isSwitchToAnnual: (state) => {
            return state.switchToAnnual;
        },

        activeTier: (state) => {
            return state.tiers.find((tier) => tier.planTier === state.planTier);
        },

        activeCadenceOptions: (state) => {
            const activeCadenceOptions = state.cadences.find(
                (cadence) => cadence.planTier === state.planTier,
            );
            return activeCadenceOptions;
        },

        currentPlan: (state) => {
            return state.currentPlan;
        },

        isSwitchPlan: (state) => {
            return state.switchPlan;
        },

        skipTier: (state) => {
            return (
                state.tiers === undefined ||
                state.tiers.length == 0 ||
                (state.tiers.length == 1 && state.cadences.length == 1) ||
                state.plans.length == 1
            );
        },
        /** Currently We never skip cadence because that finalizes the plan selection */
        skipCadence: (state) => {
            return false;
        },

        skipBasicCadence: (state, getters) => {
            return getters['basicPlans'].every((plan) => plan.allowedMigration === false);
        },

        skipStandardCadence: (state, getters) => {
            return getters['standardPlans'].every((plan) => plan.allowedMigration === false);
        },

        skipPremiumCadence: (state, getters) => {
            return getters['premiumPlans'].every((plan) => plan.allowedMigration === false);
        },

        /**
         * Verify if plan description copy is empty for all tiers
         * @param state
         * @returns {boolean}
         */
        isEmptyTierDescriptionCopy: (state) => {
            return state.tiers.every((tier) => tier?.plan_description === '');
        },

        /**
         * Verify if plan description copy is empty for all cadences
         * @param state
         * @returns {boolean}
         */
        isEmptyCadencesDescriptionCopy: (state) => {
            return state.cadences.every(
                (cadence) =>
                    cadence?.annual_plan_description === '' ||
                    cadence?.monthly_plan_description === '',
            );
        },

        /**
         *
         * @param state
         * @returns {boolean}
         */
        hasPlans: (state) => {
            return state.plans.length > 0;
        },

        /**
         * @param state
         * @returns {T[]}
         */
        monthlyPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planType === Product.TYPE_MONTHLY;
            });
        },

        /**
         *
         * @param state
         * @returns {T[]}
         */
        annualPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planType === Product.TYPE_ANNUAL;
            });
        },

        /**
         *
         * @param state
         * @returns {T[]}
         */
        basicPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planTier === Product.TIER_BASIC;
            });
        },

        /**
         *
         * @param state
         * @returns {T[]}
         */
        standardPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planTier === Product.TIER_STANDARD;
            });
        },

        premiumPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planTier === Product.TIER_PREMIUM;
            });
        },

        annualPlanFilterIsOn: (state) => {
            return state.planType === Product.TYPE_ANNUAL;
        },

        selectedPlan: (state) => {
            return state.selectedPlan;
        },

        upcomingPlan: (state) => {
            return state.upcomingPlan;
        },

        getSelectedPlan(state) {
            return state.plans.find(
                (plan) => plan.planTier === state.planTier && plan.planType === state.planType,
            );
        },

        /**
         *
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @returns {*}
         */
        getSelectedPlanTrialCopy: (state, getters, rootState, rootGetters) => {
            let selectedPlan = getters.getSelectedPlan;
            let pageAttrs = rootGetters['pageAttributes/getAllPageAttributes'];
            let plan = new Plan(selectedPlan, pageAttrs);
            return plan.getTrialCopy();
        },

        getSelectedPlanLabelKey: (state, getters, rootState, rootGetters) => {
            const selectedPlan = getters.getSelectedPlan;
            return getPlanLabelKey(
                rootGetters,
                getters,
                selectedPlan?.planTier,
                selectedPlan?.planType,
            );
        },

        getCurrentPlanLabelKey: (state, getters, rootState, rootGetters) => {
            const currentPlan = state.currentPlan;
            return getPlanLabelKey(
                rootGetters,
                getters,
                currentPlan.plan_tier,
                currentPlan.plan_type,
            );
        },

        getCadences: (state) => {
            return state.cadences;
        },
        getTiersWithValidPromos: (state) => {
            return state.tiersWithValidPromos;
        },

        getCurrentSubscription: (state, getters, rootState, rootGetters) => {
            return rootGetters.getServerDataByKey('currentSubscription');
        },

        validPromoExists: (state) => {
            return state.validPromoExists;
        },
        getDefaultHeadings: (state, getters, rootState, rootGetters) => {
            const defaultNoPromoAttrs =
                rootGetters.getServerDataByKey('multiSubPlanAttributes')?.default_nopromo;

            const stepsHeader = getters.isSwitchPlan
                ? defaultNoPromoAttrs?.switch_plan_heading
                : defaultNoPromoAttrs?.heading;

            const stepsCounter = defaultNoPromoAttrs?.aa_steps_counter;

            return { stepsHeader, stepsCounter };
        },
        getLegalOverlayAttributes: (state, getters, rootState, rootGetters) => {
            const legalAttrs = rootGetters.getServerDataByKey('multiSubPlanAttributes')?.legals;
            const selectedPlan = getters.getSelectedPlan;

            const selectedLegalAttributes = (legalAttrs || []).find(
                (legal) =>
                    legal.tags.includes(`legal_billing_${selectedPlan.planTier}`) ||
                    legal.tags.includes(`legal_plan_${selectedPlan.planTier}`),
            );
            if (!selectedLegalAttributes) {
                return {};
            }

            if (selectedPlan.planType === Product.TYPE_MONTHLY) {
                selectedLegalAttributes.chosen_legal_copy_desktop =
                    selectedLegalAttributes.legal_copy_desktop_monthly;
            }
            if (selectedPlan.planType === Product.TYPE_ANNUAL) {
                selectedLegalAttributes.chosen_legal_copy_desktop =
                    selectedLegalAttributes.legal_copy_desktop_annual;
            }

            return selectedLegalAttributes;
        },
    },
};
